.productDetail {
	.wrapper {
		div {
			div {
				.coverImg {
					width: 100%;
					height: 350px;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}
