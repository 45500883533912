.AddProduct {
	padding: 20px 0;

	.wrapper {
		.formContainer {
			form {
				.basic {
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}
}
