.loader {
	display: inline-block;
	border: 3px solid #f3f3f3;
	border-radius: 50%;
	border-top: 3px solid #000;
	width: 15px;
	height: 15px;
	margin-left: 10px;
	margin-bottom: -3px;
	-webkit-animation: spin 0.4s linear infinite; /* Safari */
	animation: spin 0.4s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
